import Page from "../page";
import ScrollMagic from 'scrollmagic';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import SplitText from "../../vendors/gsap/SplitText";

export default class SingleIndex extends Page {
    constructor(component) {
        super(component);

        // DOM
        this.dom.animateElements = this._findAll('[data-scroll]');

        // VAR
        this.disabledIntro = false;
    }

    /**
     * INI
     */
    init() {
        super.init();

        // SPLIT
        this._setSplitTexts();

        // SCROLL
        //this._setScroll();

        // BIND
        this.bind();
    }

    /**
     * BIND
     */
    bind() {
        super.bind();

        // READY
        this.ready();
    }

    /**
     * SHOW
     */
    show(i__transitionName) {
        super.show();

        if(i__transitionName === 'collection-transition')
            this.disabledIntro = true;

        this._setScroll();
        //this.controller.enabled(true);
    }

    /**
     * SET SCROLL
     * @private
     */
    _setScroll() {

        // CONTROLLER
        this.controller = new ScrollMagic.Controller();
        //this.controller.enabled(false);

        // SCENES
        for(let i = 0; i < this.dom.animateElements.length; i++) {
            let _e = this.dom.animateElements[i];
            let _type = _e.dataset.scrollType;
            let _delay = 0;
            let _hook = 0.7;

            if(_e.dataset.scrollHook)
                _hook = _e.dataset.scrollHook;

            if(_e.dataset.scrollDelay)
                _delay = _e.dataset.scrollDelay;

            let _tml = new TimelineMax({
                delay: _delay
            });

            if(this.disabledIntro && _e.dataset.scrollDisabled) {
                _e.classList.add('a-disabled');
            } else {
                // ANIMATION
                switch (_type) {
                    case 'split-letters':
                        _tml.staggerTo(_e.querySelectorAll('.letter'), 1, {
                            x:0,
                            autoAlpha: 1,
                            ease:Power4.easeOut
                        }, -0.05, 0);
                        break;
                    case 'split-lines':
                        _tml.staggerTo(_e.querySelectorAll('.line'), 1, {
                            y:0,
                            autoAlpha: 1,
                            ease:Power4.easeOut
                        }, 0.05, 0);
                        break;
                    case 'split-lines-letters':
                        let _lines = _e.querySelectorAll('.chars-line');
                        for(let i = 0; i < _lines.length; i ++) {
                            let _l = _lines[i];
                            _tml.staggerTo(_l.querySelectorAll('.letter'), 1, {
                                x:0,
                                autoAlpha: 1,
                                ease:Power4.easeOut
                            }, -0.05, 0.1 * i);
                        }
                        break;
                    case 'up':
                        _tml.to(_e, 1, {
                            y:0,
                            autoAlpha: 1,
                            ease:Power4.easeOut
                        }, 0);
                        break;
                    case 'zoom-in':
                        _tml.to(_e, 1, {
                            y:0,
                            scale: 1,
                            autoAlpha: 1,
                            ease:Power4.easeOut
                        }, 0);
                        break;
                    case 'zoom-out':
                        _tml.to(_e, 1, {
                            y:0,
                            scale: 1,
                            autoAlpha: 1,
                            ease:Power4.easeOut
                        }, 0);
                        break;
                }

                //SCENE
                new ScrollMagic.Scene({
                    triggerElement: _e,
                    reverse: false,
                    offset: 0,
                    triggerHook: _hook,
                    duration: 0
                })
                    .setTween(_tml)
                    .addTo(this.controller);
            }
        }

        if(this._find('.next-collection')) {
            new ScrollMagic.Scene({
                triggerElement: this._find('.next-collection'),
                reverse: true,
                triggerHook: 0.15,
                duration: 0
            })
                .setClassToggle("html", "bg-black")
                .addTo(this.controller);
        }

    }

    /**
     * SET SPLIT TEXT
     * @private
     */
    _setSplitTexts() {

        // TITLE
        let _elementsLetters = this._findAll('[data-scroll-type="split-letters"]');
        if(_elementsLetters.length > 0)
            new SplitText(_elementsLetters, {type:'chars', charsClass:'letter letter++'});

        let _elementLines = this._findAll('[data-scroll-type="split-lines"] p');
        if(_elementLines.length > 0)
            new SplitText(_elementLines, {type:'lines', linesClass:'line line++'});

        // COLOR TITLES
        let _elementLinesLetters = this._findAll('[data-scroll-type="split-lines-letters"]');
        if(_elementLinesLetters.length > 0)
            new SplitText(_elementLinesLetters, {type:'lines chars', charsClass:'letter letter++', linesClass:'chars-line'});
    }
}