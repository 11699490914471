import Page from "../page";
import ScrollMagic from 'scrollmagic';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import SplitText from "../../vendors/gsap/SplitText";

export default class ColorsIndex extends Page {
    constructor(component) {
        super(component);

        // DOM
        this.dom.animateElements = this._findAll('[data-scroll]');
        this.dom.containersColor = this._findAll('.container-color');
        this.dom.currentColor = this._find('.list-colors .current');
        this.dom.listColors = this._findAll('.name-color');
        this.currentName = null;

        // FUNC
    }

    /**
     * INI
     */
    init() {
        super.init();

        // SPLIT
        this._setSplitTexts();

        // BIND
        this.bind();
    }

    /**
     * BIND
     */
    bind() {
        super.bind();

        // READY
        this.ready();
    }

    /**
     * SHOW
     */
    show() {
        super.show();
        this._setScroll();
    }

    /**
     * SET SCROLL
     * @private
     */
    _setScroll() {

        // CONTROLLER
        this.controller = new ScrollMagic.Controller({
            addIndicators: false
        });
        //this.controller.enabled(false);

        // SCENES
        for(let i = 0; i < this.dom.animateElements.length; i++) {
            let _e = this.dom.animateElements[i];
            let _type = _e.dataset.scrollType;
            let _delay = 0;
            let _hook = 0.7;

            if(_e.dataset.scrollHook)
                _hook = _e.dataset.scrollHook;

            if(_e.dataset.scrollDelay)
                _delay = _e.dataset.scrollDelay;

            let _tml = new TimelineMax({
                delay: _delay
            });

            // ANIMATION
            switch (_type) {
                case 'split-letters':
                    _tml.staggerTo(_e.querySelectorAll('.letter'), 1, {
                        x:0,
                        autoAlpha: 1,
                        ease:Power4.easeOut
                    }, -0.05, 0);
                    break;
                case 'split-lines':
                    _tml.staggerTo(_e.querySelectorAll('.line'), 1, {
                        y:0,
                        autoAlpha: 1,
                        ease:Power4.easeOut
                    }, 0.05, 0);
                    break;
                case 'split-lines-letters':
                    let _lines = _e.querySelectorAll('.chars-line');
                    for(let i = 0; i < _lines.length; i ++) {
                        let _l = _lines[i];
                        _tml.staggerTo(_l.querySelectorAll('.letter'), 1, {
                            x:0,
                            autoAlpha: 1,
                            ease:Power4.easeOut
                        }, -0.05, 0.1 * i);
                    }
                    break;
                case 'up':
                    _tml.to(_e, 1, {
                        y:0,
                        autoAlpha: 1,
                        ease:Power4.easeOut
                    }, 0);
                    break;
                case 'images-up':
                    _tml.staggerTo(_e.querySelectorAll('img'), 1, {y: '0%', alpha: 1, ease: Power4.easeOut}, 0.05);
                    break;
                case 'zoom-in':
                    _tml.to(_e, 1, {
                        y:0,
                        scale: 1,
                        autoAlpha: 1,
                        ease:Power4.easeOut
                    }, 0);
                    break;
                case 'zoom-out':
                    _tml.to(_e, 1, {
                        y:0,
                        scale: 1,
                        autoAlpha: 1,
                        ease:Power4.easeOut
                    }, 0);
                    break;
            }

            //SCENE
            new ScrollMagic.Scene({
                triggerElement: _e,
                offset: 0,
                reverse: false,
                triggerHook: _hook,
                duration: 0
            })
                .setTween(_tml)
                .addTo(this.controller);

            let _off = this.offset(_e);
        }

        // NAME COLOR
        for(let i = 0; i < this.dom.containersColor.length; i++) {
            let _c = this.dom.containersColor[i];
            let _colorName = _c.dataset.name;
            let _colorHex = _c.dataset.hex;
            let _s = new ScrollMagic.Scene({
                triggerElement: _c,
                triggerHook: 0.7,
                duration: _c.clientHeight,
                reverse: true
            })
                .addTo(this.controller);

            _s.on("enter", (event) => {

                // CURRENT NAME
                if(this.currentName) {
                    TweenLite.killTweensOf(this.currentName);
                    TweenLite.to(this.currentName, 0.4, {autoAlpha: 0, ease:Power4.easeOut});
                }

                TweenLite.killTweensOf(this.dom.listColors[i].querySelectorAll('.letter'));
                TweenLite.set(this.dom.listColors[i].querySelectorAll('.letter'), {autoAlpha: 0, x: -100});

                let _tml = new TimelineMax();
                _tml.to(this.dom.listColors[i], 0.1, {autoAlpha: 1, ease:Power4.easeOut});
                _tml.staggerTo(this.dom.listColors[i].querySelectorAll('.letter'), 1, {
                    x:0,
                    autoAlpha: 1,
                    ease:Power4.easeOut
                }, -0.05, 0);

                this.currentName = this.dom.listColors[i];
            });
        }
    }

    offset(el) {
        let rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }

    /**
     * SET SPLIT TEXT
     * @private
     */
    _setSplitTexts() {

        // TITLE
        let _elementsLetters = this._findAll('[data-scroll-type="split-letters"]');
        if(_elementsLetters.length > 0)
            new SplitText(_elementsLetters, {type:'chars', charsClass:'letter letter++'});

        let _elementLines = this._findAll('[data-scroll-type="split-lines"] p');
        if(_elementLines.length > 0)
            new SplitText(_elementLines, {type:'lines', linesClass:'line line++'});

        // COLOR TITLES
        let _elementLinesLetters = this._findAll('[data-scroll-type="split-lines-letters"]');
        if(_elementLinesLetters.length > 0)
            new SplitText(_elementLinesLetters, {type:'lines chars', charsClass:'letter letter++', linesClass:'chars-line'});

        // NAME COLOR
        new SplitText(this.dom.listColors, {type:'chars', charsClass:'letter letter++'});
    }
}