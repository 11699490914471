import Component from './component';
import axios from 'axios';

export const FORM_EVENT = {
    'SUCCESS': 'form_event__success'
};

export default class Form extends Component{
    constructor(component) {
        super(component);

        // DOM
        this.dom.required = this._findAll('.required');
        this.dom.message = this._find('.form-message');
        this.dom.submit = this._find('.form-submit');

        // VAR
        this.isProcessing = false;
        this.ajaxUrl = this.dom.component.getAttribute('action');

        // FUNC
        this._validateForm = this._validateForm.bind(this);
    }

    init() {
        super.init();

        // BIND
        this.bind();
    }

    bind() {
        super.bind();
        this.dom.submit.addEventListener('click', this._validateForm);
    }

    unbind() {
        super.unbind();
        this.dom.submit.addEventListener('click', this._validateForm);
    }

    reset(resetFormMessage = false) {
        this.dom.component.reset();
        this.dom.message.classList.remove('success');

        if(resetFormMessage) {
            this.dom.message.innerHTML = '';
        }
    }

    _validateForm(event) {
        event.preventDefault();

        if(!this.isProcessing) {
            this.isProcessing = true;
            let formError = 0; // 1 -- email // 2 -- checkbox // 3 -- empty // 4 -- too short

            // TRIM INPUT
            for(let i = 0; i < this.dom.required.length; i++) {
                let input = this.dom.required[i];
                input.parentNode.classList.remove('error');
                if(input.value.trim() == '' && !input.classList.contains('can-be-empty')) {
                    input.parentNode.classList.add('error');
                    formError = 3;
                }
            }

            // INPUT TYPE
            if(formError === 0) {
                for(let i = 0; i < this.dom.required.length; i++) {
                    let input = this.dom.required[i];
                    if(input.classList.contains('email')) {
                        if(!this._isValidEmailAddress(input.value.trim())) {
                            input.parentNode.classList.add('error');
                            formError = 1;
                            break;
                        }
                    } else if(input.classList.contains('checkbox')) {
                        if(!input.checked) {
                            input.parentNode.classList.add('error');
                            formError = 2;
                            break;
                        }
                    } else if(input.classList.contains('password')) {
                        if(input.classList.contains('can-be-empty') && input.value.trim() != '') {
                            if (input.value.trim().length < 8) {
                                input.parentNode.classList.add('error');
                                formError = 4;
                                break;
                            }
                        } else if(!input.classList.contains('can-be-empty')) {
                            if (input.value.trim().length < 8) {
                                input.parentNode.classList.add('error');
                                formError = 4;
                                break;
                            }
                        }
                    }
                }
            }

            if(formError !== 0) {
                switch (formError) {
                    case 1:
                        if(CURRENT_LANG == 'fr')
                            this.dom.message.innerHTML = "Votre email n'est pas valide";
                        else
                            this.dom.message.innerHTML = "Su e-mail no es válido";
                        break;
                    case 2:
                        this.dom.message.innerHTML = "Vous devez accepter le règlement";
                        break;
                    case 3:
                        if(CURRENT_LANG == 'fr')
                            this.dom.message.innerHTML = "Tous ces champs sont obligatoires";
                        else
                            this.dom.message.innerHTML = "Todos estos campos son obligatorios";
                        break;
                    case 4:
                        this.dom.message.innerHTML = "Votre mot de passe doit comporter au moins 8 caractères";
                        break;
                }
                this.isProcessing = false;
            } else {
                this._submitForm();
            }
        }
    }

    _submitForm() {
        this.dom.message.innerHTML = '';
        let formData = new FormData(this.dom.component);

        axios({
            method: 'post',
            url: this.ajaxUrl,
            data: formData
        })
        .then((response) => {
            switch (response.data.code) {
                case 200:
                    this.dom.message.classList.add('success');
                    if(response.data.message)
                        this.dom.message.innerHTML = response.data.message;
                    this.emit(FORM_EVENT.SUCCESS, response.data.data);
                    break;
                case 300:
                    this.dom.message.innerHTML = response.data.message;
                    break;
                case 404:
                    this.dom.message.innerHTML = response.data.message;
                    break;
            }
        })
        .catch((error) => {
            console.log(error);
        })
        .then(() => {
            this.isProcessing = false;
        });
    }

    _isValidEmailAddress(emailAddress) {
        let pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
        return pattern.test(emailAddress);
    };
}