import {PAGE_EVENT} from "./pages/page";
import Page from "./pages/page";
import HomeIndex from "./pages/home";
import SingleIndex from "./pages/single";
import ColorsIndex from "./pages/colors";
import WorkshopIndex from "./pages/workshop";
import ContactIndex from "./pages/contact";
import device from "current-device";
import barba from '@barba/core';
import _ from 'lodash';
import Preload, {PRELOAD_EVENT} from "./components/preload";
import Ui from "./components/ui";
import {TimelineMax} from "gsap/TimelineMax";

export default class App {
    constructor() {

        // DOM
        this.dom = {
            html: document.querySelector('html'),
            body: document.querySelector('body'),
            wrapper: document.querySelector('.wrapper'),
            main: document.querySelector('#main'),
            headerFixed: document.querySelector('#header-fixed'),
            headerMobile: document.querySelector('#menu-mobile'),
            transitionHelper: document.querySelector('.transition-helper .grid .row')
        };

        // VAR
        this.page = null;
        this.firstAppear = true;
        this.barbaPrevPage = null;
        this.barbaNextPage = null;
        this.transitionTypeName = null;
        this.prevScrollY = 0;
        this.currentScrollY = 0;
        this.scrollDelta = 100;
        this.mobileMenuIsOpen = false;

        // INIT
        this.init();
    }

    /**
     * INIT
     */
    init() {

        // UI
        this.ui = new Ui(document.querySelector('#header'));
        this.ui.init();

        // PRELOAD
        this.preload = new Preload(document.querySelector('#preload'));
        this.preload.on(PRELOAD_EVENT.COMPLETE, this.handlerPreloadComplete.bind(this));
        this.preload.on(PRELOAD_EVENT.SHOW, this.handlerPreloadShow.bind(this));
        this.preload.on(PRELOAD_EVENT.HIDE, this.handlerPreloadHide.bind(this));
        this.preload.init();

        this.bind();

        // BUILD
        //this.build();
    }

    /**
     * BIND
     */
    bind() {

        // MOBILE
        document.querySelector('#header .menu-mobile').addEventListener('click', () => {
            this.openMobileMenu();
        });

        document.querySelector('#header-fixed .menu-mobile').addEventListener('click', () => {
            this.openMobileMenu();
        });

        document.querySelector('#menu-mobile .close-mobile').addEventListener('click', () => {
            this.closeMobileMenu();
        });

        // EVENTS
        barba.hooks.beforeAppear((data) => {
            //this.preload.show();
            console.log('**** BEFORE APPEAR ****');
            // CURRENT
            this.barbaPrevPage = data.current;
            this.barbaNextPage = data.next;

            return new Promise(resolve => {
                this.preload.show(resolve);
            });
        });

        barba.hooks.afterAppear((data) => {
            console.log('**** AFTER APPEAR ****');
            this.build();
        });

        barba.hooks.afterLeave((data) => {
            console.log('----- AFTER LEAVE -----');
            this.dom.html.classList.remove('bg-black');
            this.page.clean();
        });

        // BEFORE ENTER
       barba.hooks.beforeEnter((data) => {

            // CURRENT
            this.barbaPrevPage = data.current;
            this.barbaNextPage = data.next;

            // BODY CLASS NAME
            let regexp = /\<body.*\sclass=["'](.+?)["'].*\>/gi,
                match = regexp.exec(this.barbaNextPage.html);
            if(!match || !match[1]) return;
            document.body.setAttribute('class', match[1]);

        });

        barba.hooks.afterEnter((data) => {
            console.log('----- AFTER ENTER -----');
            this.build();
        });

        // INIT BARBA
        barba.init({
            transitions: [
                {
                    name: 'side-panel',
                    appear: (data) => this.enterSidePanel(data),
                    leave: (data) => this.leaveSidePanel(),
                    enter: (data) => this.enterSidePanel(data),
                },
                {
                    name: 'collection-transition',
                    from: {
                        custom: ({ trigger }) => {
                            return trigger.classList && trigger.classList.contains('use-collection-transition');
                        },
                    },
                    leave: (data) => this.leaveCollectionTransition(data),
                    enter: ({ next }) => this.enterCollectionTransition(next),
                }
        ],
            views: [{
                namespace: 'contact',
                beforeEnter : () => {
                    console.log('VIEW BEFORE ENTER');
                    this.dom.html.classList.add('bg-black');
                }
            }],
            debug: true
        });

        // WINDOW
        window.addEventListener('scroll', _.throttle(this.scroll.bind(this), 10));
        window.addEventListener('resize', _.throttle(this.resize.bind(this), 100));
    }

    /**
     * Leave side panel
     * @returns {Promise<unknown>}
     */
    leaveSidePanel() {
        console.log('-------- LEAVE ---------');
        this.transitionTypeName = 'panel-transition';
        return new Promise(resolve => {
            this.preload.show(resolve);
        });
    }

    /**
     * Enter side panel
     * @returns {Promise<unknown>}
     */
    enterSidePanel() {
        return new Promise(resolve => {
            this.preload.loadAllImages(resolve, this.barbaNextPage);
        });
    }

    /**
     * Leave collection transition
     */
    leaveCollectionTransition(data) {
        this.transitionTypeName = 'collection-transition';

        return new Promise(resolve => {

            this.dom.transitionHelper.classList.remove('background');
            TweenLite.set('.transition-helper', {alpha: 1});
            this.dom.transitionHelper.innerHTML = '';

            let _name = document.querySelector('.next-collection .next-collection-name');
            let _image = document.querySelector('.next-collection .next-illustration');
            let _cloneName = _name.cloneNode(true);
            let _cloneImage = _image.cloneNode(true);
            let _nameFinalPosY = document.querySelector('.page-content .page-title').getBoundingClientRect().top + window.scrollY;
            let _imageFinalPosY = document.querySelector('.page-content .key-illustration').getBoundingClientRect().top + window.scrollY;
            let _responsiveDelta = 80;

            if(window.innerWidth <= 1279)
                _responsiveDelta = 60;

            if(window.innerWidth <= 1023)
                _responsiveDelta = 24;

            let _nameOffset = _name.getBoundingClientRect();
            let _imageOffset = _image.getBoundingClientRect();

            this.dom.transitionHelper.appendChild(_cloneImage);
            this.dom.transitionHelper.appendChild(_cloneName);

            _name.style.visibility = 'hidden';
            _image.style.visibility = 'hidden';

            _cloneName.style.transform = `translateY(${_nameOffset.top}px)`;
            _cloneImage.style.transform = `translateY(${_imageOffset.top}px)`;

            let _tml = new TimelineMax({
                onComplete: () => {
                    this.dom.transitionHelper.classList.add('background');
                    resolve();
                }
            });

            _tml.to(data.current.container, 0.8, {alpha: 0, ease:Power4.easeOut}, 0);
            _tml.to(_cloneName, 0.8, {y:_nameFinalPosY, color: '#000000', ease:Power4.easeOut}, 0.4);
            _tml.to(_cloneImage.querySelector('.inner'), 1, {scale:1, ease:Power4.easeOut}, 0.4);
            _tml.to(_cloneImage, 1, {y:_imageFinalPosY + _responsiveDelta, ease:Power4.easeOut}, 0.4);
        });
    }

    /**
     * Enter collection transition
     */
    enterCollectionTransition() {
        return new Promise(resolve => {
            this.preload.setAllImages(resolve, this.barbaNextPage);
            resolve();
        });
    }

    /**
     * BUILD
     */
    build() {
        switch (this.barbaNextPage.namespace) {
            case 'home':
                this.page = new HomeIndex(this.barbaNextPage.container);
                break;
            case 'single':
                this.page = new SingleIndex(this.barbaNextPage.container);
                break;
            case 'colors':
                this.page = new ColorsIndex(this.barbaNextPage.container);
                break;
            case 'workshop':
                this.page = new WorkshopIndex(this.barbaNextPage.container);
                break;
            case 'contact':
                this.page = new ContactIndex(this.barbaNextPage.container);
                break;
            default:
                this.page = new Page(this.barbaNextPage.container);
                break;
        }

        window.scrollTo(0, 0);

        this.page.once(PAGE_EVENT.READY, this.pageIsReady.bind(this));
        this.page.init();
    }

    /**
     * PAGE IS READY
     * @param event
     */
    pageIsReady(event) {
        this.scroll();
        this.resize();

        if(this.transitionTypeName !== 'collection-transition') {
            if(this.mobileMenuIsOpen)
                this.closeMobileMenu();

            this.preload.hide();
        } else {
            TweenLite.to('.transition-helper', 0.1, {alpha: 0});
            this.handlerPreloadHide();
        }
    }

    openMobileMenu() {
        this.mobileMenuIsOpen = true;
        let _t = new TimelineMax();
        _t.to('#menu-mobile', 0.8, {autoAlpha: 1, ease:Power4.easeOut});
        _t.staggerFrom('#menu-mobile .main li', 0.6, {y:10, alpha: 0, ease:Power4.easeOut}, 0.1, 0.3);
        _t.staggerFrom('#menu-mobile .foot li', 0.6, {y:10, alpha: 0, ease:Power4.easeOut}, 0.1, 0.4);
    }

    closeMobileMenu() {
        this.mobileMenuIsOpen = false;
        let _t = new TimelineMax();
        _t.to('#menu-mobile', 0.4, {autoAlpha: 0, ease:Power4.easeOut});
    }

    /**
     * Handler preload complete
     */
    handlerPreloadComplete() {
        //this.build();
    }

    /**
     * Hanlder preload show
     */
    handlerPreloadShow() {
        //this.build();
    }

    /**
     * Handler preload hide
     */
    handlerPreloadHide() {

        // FIRST
        if(this.firstAppear) {
            this.firstAppear = false;
            this.ui.show();

            // WRAPPER
            this.dom.wrapper.style.opacity = 1;
            this.dom.wrapper.style.visibility = 'visible';
            this.dom.wrapper.style.height = 'auto';
        }

        this.page.show(this.transitionTypeName);
    }

    /**
     * SCROLL
     */
    scroll() {
        const _currentScrollY = window.scrollY;
        const _headerHeight = 210;

        // PAGE
        if(this.page)
            this.page.scroll();

        // HEADER
        if(Math.abs(this.prevScrollY - _currentScrollY) <= this.scrollDelta)
            return;
        
        if ( (_currentScrollY > this.prevScrollY && this.dom.headerFixed.classList.contains('is-visible')) || (_currentScrollY < _headerHeight && this.dom.headerFixed.classList.contains('is-visible')) ){
            this.dom.headerFixed.classList.remove('is-visible');
        } else if(_currentScrollY < this.prevScrollY && _currentScrollY > _headerHeight && !this.dom.headerFixed.classList.contains('is-visible')) {
            this.dom.headerFixed.classList.add('is-visible');
        }

        this.prevScrollY = _currentScrollY;
    }

    /**
     * RESIZE
     */
    resize() {
        if(this.page)
            this.page.resize();
    }
}

const __dcl = () => {
    new App();
};

document.addEventListener('DOMContentLoaded', __dcl);