import Component from "../components/component";
import createjs from 'preload-js';

export const PRELOAD_EVENT = {
    'COMPLETE': 'preload_event__complete',
    'HIDE': 'preload_event__hide',
    'SHOW': 'preload_event__show'
};

export default class Preload extends Component {
    constructor(component) {
        super(component);

        this.dom.image = this._find('.translate');
        this.dom.wrapper = document.querySelector('.wrapper');

        // VARS
        this.firstAppear = true;
        this.promiseResolve = null;

        // FUNC
        this._handleAllComplete = this._handleAllComplete.bind(this);
    }

    /**
     * INIT
     */
    init() {
        super.init();
    }

    /**
     * BIND
     */
    bind() {
        super.bind();
    }

    /**
     * SHOW
     */
    show(i__func) {
        super.show();

        let _tml = new TimelineMax({
            onComplete: () => {
                this.firstAppear = false;

                this.emit(PRELOAD_EVENT.SHOW);
                if(i__func)
                    i__func();
            }
        });
        _tml.set(this.dom.image, {alpha: 0});
        if(!this.firstAppear) {
            _tml.set(this.dom.component, {autoAlpha: 0});
            _tml.to(this.dom.component, 0.8, {autoAlpha: 1, ease:Power4.easeOut}, 0);
        }
        _tml.to(this.dom.image, 1, {alpha:1, ease:Power4.easeOut}, 0.4);
    }

    /**
     * HIDE
     */
    hide() {
        let _tml = new TimelineMax({
            onComplete: () => {
                this.dom.wrapper.style.height = 'auto';
                this.emit(PRELOAD_EVENT.HIDE);
            }
        });
        _tml.to(this.dom.image, 0.8, {alpha:0, ease:Power4.easeIn});
        _tml.to(this.dom.component, 0.8, {autoAlpha: 0, ease:Power4.easeOut}, 0.4);
    }

    /**
     * LOAD IMAGE
     */
    loadAllImages(i__func, i__container) {

        // RESOLVE
        if(i__func)
            this.promiseResolve = i__func;

        const _images = i__container.container.querySelectorAll('[data-src]');

        if(_images.length > 0) {
            let _manifest = [];
            let _queue = new createjs.LoadQueue(false);
            _queue.setMaxConnections(8);
            _queue.on('fileload', this._handleFileComplete);
            _queue.on('complete', this._handleAllComplete);

            for(let i = 0; i < _images.length; i++) {
                if(_images[i].dataset.src) {
                    _manifest.push({
                        id: i,
                        src: _images[i].dataset.src,
                        target: _images[i]
                    });
                }
            }

            _queue.loadManifest(_manifest);
        } else {
            this._handleAllComplete();
        }
    }

    /**
     * SET ALL IMAGE
     */
    setAllImages(i__func, i__container) {
        let _images = i__container.container.querySelectorAll('[data-src]');
        for(let i = 0; i < _images.length; i++) {
            _images[i].setAttribute('src', _images[i].dataset.src);
        }
    }

    /**
     * FILE COMPLETE
     * @param event
     * @private
     */
    _handleFileComplete(event) {
        event.item.target.setAttribute('src', event.item.src);
    }

    /**
     * ALL COMPLETE
     * @param event
     * @private
     */
    _handleAllComplete(event) {
        console.log('PRELOAD ALL COMPLETE');

        if(this.promiseResolve)
            this.promiseResolve();

        this.emit(PRELOAD_EVENT.COMPLETE);
    }
}