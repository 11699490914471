import Page from "../page";
import ScrollMagic from 'scrollmagic';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import SplitText from "../../vendors/gsap/SplitText";

export default class WorkshopIndex extends Page {
    constructor(component) {
        super(component);

        // DOM
        this.dom.animateElements = this._findAll('[data-scroll]');
        this.dom.parallaxContainers = this._findAll('.parallax-container');

        // VAR
        this.requestAnimationFrame = null;
        this.isEnabled = false;
        this.scrollTop = 0;
        this.triggerHook = 0;
        this.tweened = 0;
        this.parallaxImages = [];
        this.test = 1.2;
        this.isMobile = document.querySelector('html').classList.contains('mobile') || document.querySelector('html').classList.contains('tablet');

        // FUNC
        this.update = this.update.bind(this);
    }

    /**
     * INI
     */
    init() {
        super.init();

        // SPLIT
        this._setSplitTexts();

        // PARALLAX
        this.setParallax();

        // BIND
        this.bind();
    }

    /**
     * BIND
     */
    bind() {
        super.bind();

        // READY
        this.ready();
    }

    /**
     * SHOW
     */
    show() {
        super.show();
        this._setScroll();
        //this.controller.enabled(true);
    }
    /**
     * SET SCROLL
     * @private
     */
    _setScroll() {

        // CONTROLLER
        this.controller = new ScrollMagic.Controller();
        //this.controller.enabled(false);

        // SCENES
        for(let i = 0; i < this.dom.animateElements.length; i++) {
            let _e = this.dom.animateElements[i];
            let _type = _e.dataset.scrollType;
            let _delay = 0;
            let _alpha = 1;

            if(_e.dataset.scrollAlpha)
                _alpha = _e.dataset.scrollAlpha;

            if(_e.dataset.scrollDelay)
                _delay = _e.dataset.scrollDelay;

            let _tml = new TimelineMax({
                delay: _delay
            });

            // ANIMATION
            switch (_type) {
                case 'split-letters':
                    _tml.staggerTo(_e.querySelectorAll('.letter'), 1, {
                        x:0,
                        autoAlpha: _alpha,
                        ease:Power4.easeOut
                    }, -0.05, 0);
                    break;
                case 'split-lines':
                    _tml.staggerTo(_e.querySelectorAll('.line'), 1, {
                        y:0,
                        autoAlpha: _alpha,
                        ease:Power4.easeOut
                    }, 0.05, 0);
                    break;
                case 'split-lines-letters':
                    let _lines = _e.querySelectorAll('.chars-line');
                    for(let i = 0; i < _lines.length; i ++) {
                        let _l = _lines[i];
                        _tml.staggerTo(_l.querySelectorAll('.letter'), 1, {
                            x:0,
                            autoAlpha: _alpha,
                            ease:Power4.easeOut
                        }, -0.05, 0.1 * i);
                    }
                    break;
                case 'up':
                    _tml.to(_e, 1, {
                        y:0,
                        autoAlpha: _alpha,
                        ease:Power4.easeOut
                    }, 0);
                    break;
                case 'zoom-in':
                    _tml.to(_e, 1, {
                        y:0,
                        scale: 1,
                        autoAlpha: _alpha,
                        ease:Power4.easeOut
                    }, 0);
                    break;
                case 'zoom-out':
                    _tml.to(_e, 1, {
                        y:0,
                        scale: 1.001,
                        autoAlpha: _alpha,
                        ease:Power4.easeOut
                    }, 0);
                    break;
            }

            //SCENE
            new ScrollMagic.Scene({
                triggerElement: _e,
                reverse: false,
                offset: 0,
                triggerHook: 0.7,
                duration: 0
            })
                .setTween(_tml)
                .addTo(this.controller);
        }

        // ADD SCENE
        if(!this.isMobile) {
            let _section1Tml = new TimelineMax();

            _section1Tml.to('.section-1 .i-2', 2.2, {y: '-120%', ease:Linear.easeNone});
            _section1Tml.to('.section-1 .i-1', 2.5, {y: '-120%', ease:Linear.easeNone}, 0);

            new ScrollMagic.Scene({
                reverse: true,
                triggerHook: 1,
                duration: window.innerHeight
            })
                .setTween(_section1Tml)
                .setPin('.section-1')
                .addTo(this.controller);
        }

        new ScrollMagic.Scene({
            triggerElement: this._find('.section-2'),
            reverse: true,
            triggerHook: 0.5,
            duration: 0
        })
            .setClassToggle("html", "bg-black")
            .addTo(this.controller);

        this.isEnabled = true;
        this.update();
    }

    scroll() {
        if(this.isEnabled) {
            this.triggerHook = window.innerHeight;
            this.scrollTop = Math.max(0, document.documentElement.scrollTop || window.pageYOffset || 0);
        }
    }

    /**
     * UPDATE
     */
    update() {
        if(this.isEnabled) {

            // PARALLAX
            for(let i = 0; i < this.parallaxImages.length; i++) {
                let __target = this.parallaxImages[i];

                const __targetHeightDistance = __target.element.offsetHeight + this.triggerHook;
                const __targetTop = __target.element.getBoundingClientRect().top - this.triggerHook;
                const __percentageY = __targetTop / __targetHeightDistance;
                const __percentageDelta = Math.max(0, __target.image.offsetHeight - __target.element.offsetHeight) * __percentageY;
                const __top = __target.tweened += (__percentageDelta - __target.tweened);

                __target.image.style.transform = `translateX(0) translateY(${((__top * __target.velocity) * 1)}px) translateZ(0)`;
            }
        }

        this.requestAnimationFrame = window.requestAnimationFrame(this.update);
    }

    setParallax() {
        const _elements = document.querySelectorAll('.parallax-container');
        for(let i = 0; i < _elements.length; i++) {
            let __target = _elements[i];
            let __image = __target.querySelector('img');
            let __velocity = 0;
            __image.style.transform = 'translateX(0) translateY(0) translateZ(0)';

            if(__target.dataset.parallaxVelocity)
                __velocity = __target.dataset.parallaxVelocity;

            this.parallaxImages.push({
                element: __target,
                image: __image,
                velocity: __velocity + 1,
                tweened: 0
            });
        }
    }

    clean() {
        super.clean();
        cancelAnimationFrame(this.requestAnimationFrame);
    }

    /*    enabledObserver() {
            this.animateElements.forEach(image => {
                this.observer.observe(image);
            });
        }

        disabledObserver() {
            this.animateElements.forEach(image => {
                this.observer.unobserve(image);
            });
        }*/

    /**
     * SET SPLIT TEXT
     * @private
     */
    _setSplitTexts() {

        // TITLE
        let _elementsLetters = this._findAll('[data-scroll-type="split-letters"]');
        if(_elementsLetters.length > 0)
            new SplitText(_elementsLetters, {type:'chars', charsClass:'letter letter++'});

        let _elementLines = this._findAll('[data-scroll-type="split-lines"] p');
        if(_elementLines.length > 0)
            new SplitText(_elementLines, {type:'lines', linesClass:'line line++'});

        // COLOR TITLES
        let _elementLinesLetters = this._findAll('[data-scroll-type="split-lines-letters"]');
        if(_elementLinesLetters.length > 0)
            new SplitText(_elementLinesLetters, {type:'lines chars', charsClass:'letter letter++', linesClass:'chars-line'});
    }
}