import Component from "../components/component";

export const PAGE_EVENT = {
    'READY': 'page_event__ready',
    'CLEAN': 'page_event__clean',
};

export default class Page extends Component {
    constructor(component) {
        super(component);

        // VAR
        this.controller = null;
    }

    /**
     * INIT
     */
    init() {
        super.init();
    }

    /**
     * BIND
     */
    bind() {
        super.bind();
    }

    /**
     * UNBIND
     */
    unbind() {
        super.unbind();
    }

    /**
     * READY
     */
    ready() {
        this.emit(PAGE_EVENT.READY);
    }

    /**
     * CLEAN
     */
    clean() {
        // CONTROLLER
        if(this.controller)
            this.controller.destroy(true);

        // UNBIND
        this.unbind();
    }
}