import Page from "../page";
import SplitText from "../../vendors/gsap/SplitText";

export default class HomeIndex extends Page {
    constructor(component) {
        super(component);

        // DOM
        this.dom.collections = this._findAll('.item-collection');

        // VAR
        this.isMobile = document.querySelector('html').classList.contains('mobile') || document.querySelector('html').classList.contains('tablet');

        // FUNC
        this._enterCollection = this._enterCollection.bind(this);
        this._leaveCollection = this._leaveCollection.bind(this);
    }

    init() {
        super.init();

        // SPLIT
        this._setSplitTexts();

        // BIND
        this.bind();
    }

    bind() {
        super.bind();

        if(!this.isMobile) {
            for(let i = 0; i < this.dom.collections.length; i++) {
                let _c = this.dom.collections[i];
                _c.querySelector('a').addEventListener('mouseenter', (e) => {
                    this._enterCollection(e, _c.dataset.collectionid);
                });
                _c.querySelector('a').addEventListener('mouseleave', () => {
                    this._leaveCollection(_c.dataset.collectionid);
                });
            }
        }

        // READY
        this.ready();
    }

    _setSplitTexts() {
        for(let i = 0; i < this.dom.collections.length; i++) {
            new SplitText(this.dom.collections[i].querySelector('.text'), {type:'chars', charsClass:'letter letter++'})
        }
    }

    show() {
        let _tl = new TimelineMax();

        setTimeout(() => {
            document.querySelector('#home').classList.remove('disabled');
        }, 500);

        for(let i = 0; i < this.dom.collections.length; i++) {
            let _c = this.dom.collections[i];
            let _s = _c.querySelectorAll('.letter');
            let _g = _c.querySelector('.gender');

            _tl.staggerTo(_s, 1, {
                x:0,
                autoAlpha: 1,
                ease:Power4.easeOut,
                onComplete: () => {
                    TweenLite.to(_g, 0.6, {scale:1, autoAlpha:1, y:0, ease: Power4.easeOut});
                }
            }, -0.05, 0.40*i);
        }

        _tl.staggerTo('.title-collection', 0.8, {y: 0, autoAlpha: 1, ease:Power4.easeOut}, 0.1, 0.8);
    }

    _enterCollection(event, i__id) {
        let _i = this._find('.collectionid-' + i__id);
        let _tl = new TimelineMax();
        _tl.set(_i.querySelectorAll('.abs img'), {y: '10%', alpha:0});
        _tl.staggerTo(_i.querySelectorAll('.abs img'), 1, {y: '0%', alpha: 0.85, ease: Power4.easeOut}, 0.05);

        for(let i = 0; i < this.dom.collections.length; i++) {
            let _c = this.dom.collections[i];
            let _t = _c.querySelector('.text');
            _t.classList.add('grey');
        }
    };

    _leaveCollection(i__id) {
        let _i = this._find('.collectionid-' + i__id);
        let _tl = new TimelineMax();
        _tl.staggerTo(_i.querySelectorAll('.abs img'), 0.5, {y: '-10%', alpha: 0, ease: Power4.easeOut}, 0.05);

        for(let i = 0; i < this.dom.collections.length; i++) {
            let _c = this.dom.collections[i];
            let _t = _c.querySelector('.text');
            _t.classList.remove('grey');
        }
    }
}