import Component from "../components/component";

export default class Ui extends Component {
    constructor(component) {
        super(component);
    }

    /**
     * INIT
     */
    init() {
        super.init();
    }

    /**
     * BIND
     */
    bind() {
        super.bind();
    }

    /**
     * SHOW
     */
    show() {
        super.show();

        let _tml = new TimelineMax();
        _tml.staggerTo('#header .logo, #header .menu li, #header .menu-mobile, #footer li', 0.6, {alpha:1, y: 0, ease:Power4.easeOut}, 0.05);
    }
}